<template>
    <v-card style="height: 100%;"  :class="{videoFil:enableFil}">
        <div class="d-flex" style="height: 100%;">
            <div
                :id="registry.id"
                class="stream-player mx-auto"
            ></div>
            <div class="examination-Room--name text-right">
                <slot name="title"></slot>

            </div>
            <div class="exam-room-info">
                <slot name="info"></slot>
            </div>
            <div class="operate button-tip"  v-show="playSuccess">
                  <span class="blanWidthSty" v-show="itemObj&&itemObj.itemBandWidth">
                   {{itemObj.itemBandWidth}} Kbps
                  </span>
                <div>
                    <v-btn
                        class="mute-icon notPlay-icon"
                        v-if="playSuccess && audioMute"
                        color="white"
                        icon
                        @click="UnMuteAudio">
                        <v-icon>mdi-volume-mute</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="playSuccess && !audioMute"
                        class="mute-icon play-icon"
                        color="white"
                        icon
                        @click="MuteAudio">
                        <v-icon>mdi-volume-high</v-icon>
                    </v-btn>
                </div>

                <el-tooltip class="item" effect="dark" content="全屏查看" placement="top-start">
                    <el-button @click="handleFull" class="el-icon-full-screen"></el-button>
                </el-tooltip>
            </div>
            <div class="nostream" v-show="!playSuccess">
                无信号
            </div>
        </div>
    </v-card>
</template>

<script>
    import screenfull from 'screenfull';
    export default {
        props: ['registry','monitor', 'enableFil','itemObj'],
        inject: ['enums'],
        methods: {

            handleFull() {
                let id = this.registry.id
                let target = document.getElementById(id)
                if (screenfull.isEnabled) {
                    screenfull.request(target)
                }
            },
            Bind(stream) {
                if (this.hasStream) {
                    this.stream.stop();
                    this.stream.close();
                }
                this.stream = stream;
                this.stream.on('player-state-changed', this.StateChange);
                this.Play();
            },
            async Play() {
                try {
                    this.MuteAudio()
                    await this.stream.play(this.registry.id, { objectFit: 'contain' })
                    this.streamStatus.paused = false;
                    this.playSuccess = true;
                } catch (e) {
                    this.playSuccess = true;
                    let errorCode = e.getCode();
                    if (errorCode === 0x4043) {
                        this.streamStatus.paused = true;
                    }
                }
            },
            MuteAudio() {
                if (this.stream.muteAudio()) {
                    this.audioMute = true;
                }
            },
            UnMuteAudio() {
                this.disableVoice();
                if (this.stream.unmuteAudio()) {
                    this.audioMute = false;
                }
            },
            disableVoice() {
                let el = document.querySelectorAll('.play-icon');
                if(el.length > 0) {
                    el.forEach((v) => {
                        v.click();
                    });
                }
            },
            StateChange(event) {
                this.state = event.state;
            },
        },
        async created() {
          console.log(this.itemObj,111);
        },
        computed: {
            hasStream() {
                return Object.keys(this.stream).length !== 0;
            }
        },
        beforeDestroy() {
            this.monitor.Leave()
        },
        data() {
            return {
                streamStatus: {
                    paused: false,
                },
                video: {
                    state: '',
                },
                state: '',
                stream: {},
                streamFlow: {},
                audioMute: true,
                audioMuteFlow: true,
                streamStatusFlow: {
                    paused: false,
                },
                playSuccess: false,
                playSuccessFlow: false,
            };
        },
    };
</script>

<style scoped>
    .nostream {
        position: absolute;
        /* top: 30%; */
        color: #fff;
        text-align: center;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .registry-card {
        /* width: 477px;
        height: 256px; */
        width: 100%;
        height: 100%;
        background-color: #1a1a1a;
    }

    .stream-player {
        width: 100%;
        height: 100%;
        /* width: 477px;
        height: 256px; */
        background-color: #1a1a1a;
    }
    .examination-Room--name {
        width: 100%;
        color: #fff;
        font-weight: bold;
        position: absolute;
        top: 0;
        right: 0;
    }
    .operate {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
    }
</style>
<style lang="scss">
.blanWidthSty{
  font-size: 14px;
  color: white;
  margin-right: 6px;
}
.button-tip {
    .el-button {
        background: transparent !important;
        border: none !important;
        font-size: 24px;
        color: white;
        padding: 1px 10px;
    }
}

.exam-room-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
}

.RoomInfo {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0;
    color: #fff;
    background-color: rgba(0,0,0,0.75);
    padding: 6px;
    right: 0;
}

.RoomTitle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 10px;
    position: relative;

    .RoomTitle-L {
        flex: 1;
        text-align: center;

        .s {
            margin: 0 5px;
        }
    }

    .RoomTitle-R {
        display: flex;
        align-items: center;
        margin-left: 20px;
    }
}
</style>
<style>
    .video-item video {
        object-fit: contain !important;
    }
    .videoFil video {
        object-fit: fill !important;
    }

</style>
