<template>
    <div style="height: 100%">
        <v-toolbar dense dark>
            <v-row class="text-left" no-gutters>
                <v-col class="title">
                   <span v-if="showLeftMenu">
                    {{ title }}
                   </span>
                   <span v-else>
                    {{ area }}
                    <span v-if="name">-{{ name }}</span>
                   </span>
                   <span>（共{{ $attrs.count }}个视频）</span>
                </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <slot name="options">
                <v-btn color="white" class="mr-2" outlined @click="prev">
                    上一页
                </v-btn>
                <v-btn color="white" class="mr-2" outlined @click="next">
                    下一页
                </v-btn>
            </slot>
        </v-toolbar>
        <v-row no-gutters>
            <!-- <div v-for="(item, i) in items" :key="item.userId" class="video-item" :style="playerStyle"  :enableFil="enableFil">
                <registry
                    :ref="item.userId"
                    :registry="{id: item.userId}"
                    :monitor="allMonitor[i].monitor"
                     :enableFil="enableFil"
                     :itemObj="item"
                    >
                    <template #info>
                        <div class="RoomInfo">
                            <div class="RoomTitle-R">
                                <span class="s">{{ GetRoomText(i) }}</span>
                            </div>
                        </div>
                    </template>
                </registry>
            </div> -->
            <div v-for="(item, i) in 9" :key="i"  id="equipments"
            :index="i"
            class="video-item" :style="playerStyle"
            :class="i<items.length?'show':'hidden'"
            >
                <!-- <video-player
                    class="monitor-div"
                    :playerstyle="player"
                    :url="GetPlayerUrl(i)"
                    :vid="'tcplayer'+i"
                    :enableFil="enableFil"
                >
                    <template #title>
                        <div class="RoomTitle">
                            <div class="RoomTitle-L">
                                <span class="s">{{ GetRoomText(i) }} </span>
                            </div>
                        </div>
                    </template>
                </video-player> -->

                <Hls-Player :src="GetPlayerUrl(i)" :enableFil="enableFil" :vid="'tcplayer'+i">
                    <template #title>
                        <div class="RoomTitle">
                            <div class="RoomTitle-L">
                                <span class="s">{{ GetRoomText(i) }}</span>
                            </div>
                        </div>
                    </template>
                </Hls-Player>
            </div>
        </v-row>
    </div>
</template>

<script>
    import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
    // import TrtcMonitor from '@/infrastructures/trtc/monitor';
    import VideoPlayer from '@/components/videoPlayer/index';
    import HlsPlayer from '@/components/hlsPlayer/index';
    import registry from '@/components/trtc/registry';
    import Timer from '@/infrastructures/timer/timer';
    import { EventBus } from '@/utils/event-bus.js';
    export default
    @Component({
        components: {
            registry,
            VideoPlayer,
            HlsPlayer
        },
    })
    class MonitorViewComponent extends Vue {
        @Prop() items;
        @Prop() title;
        @Prop() width;
        @Prop() height;
        @Prop() enableFil;

        get playerStyle() {
            return {
                width: `${this.player.width}px`,
                height: `${this.player.height}px`,
            };
        }

        player = {
            width: 640,
            height: 360,
        };
        nowDate = '';
        timer = Timer.CreateFromSecond(this.formatDate, 1);

        area = ''
        name = ''

        allMonitor = [];
        mainJoin = 0;
        showLeftMenu = false

        mounted() {
            this.$nextTick(() => {
                this.timer.Start();
                this.OnItemsChanged(this.items);
            });
            window.addEventListener('beforeunload', this.beforeunloadFn);
            EventBus.$on('resetMonitorList',()=>{
                this.resetMonitor()
            })
            if(!this.$route.query.leftMenu) {
                this.name= this.$route.query.name || ''
                this.area = this.$route.query.code
            } else {
                this.showLeftMenu = true
            }
        }

        resetMonitor() {
            // if(this.allMonitor.length > 0) {
            //     this.allMonitor = []
            //     this.mainJoin = 0
            // }
        }

        beforeunloadFn() {
            // this.allMonitor.map((v) => {
            //     v.monitor.Leave();
            // });
            // this.allMonitor.map((v) => {
            //     v.monitor.Destroy();
            // });
            if (this.timer) {
                this.timer.Stop();
            }
            // this.allMonitor = []
         }

        beforeDestroy() {
            this.beforeunloadFn()
            window.removeEventListener('beforeunload', this.beforeunloadFn);
        }

        muted() {
            for (let index = 0; index < 9; index++) {
                let ref = this.$refs.player[index]
                ref.muted()
            }
            // for (const item of this.items) {
            //     this.$refs[item.userId][0].MuteAudio()
            // }
        }

        unmuted() {
            for (let index = 0; index < 9; index++) {
                let ref = this.$refs.player[index]
                ref.unmuted()
            }
            // for (const item of this.items) {
            //     this.$refs[item.userId][0].unMuteAudio()
            // }
        }

        destroyed() {
            if (this.timer) {
                this.timer.Stop();
            }
        }

        @Watch('items', { immediate: true, deep: true })
        async OnItemsChanged(val) {
            let count = val.length,
                width = this.width ?? this.$el.clientWidth,
                height = (this.height ?? this.$el.clientHeight) - 48; //减去header高度
            this.Calc(count, width, height);
            // if(val.length > 0 && this.allMonitor.length === 0) {
            //     // 初始化 monitor 客户端
            //     await this.handleInitMonitor();
            // }
        }

        // async handleInitMonitor() {
        //     for(const element of this.items) {
        //         await this.handleInit(element);

        //     }
        //     await this.Join();
        // }

        // async handleInit(item) {
        //     let obj = {};
        //     obj.id = item.userId;
        //     let monitor = await TrtcMonitor.CreateMonitor(item.monitorId, item.monitorIdSig, item.sdkAppId,this);
        //     monitor.WhenClientJoin(async (event) => {
        //         await this.ClientPush(event, monitor);
        //     });
        //     monitor.AfterClientJoin(this.ClientJoined);
        //     obj.monitor = monitor;
        //     obj.roomId = Number(item.trtcRoomId);
        //     this.allMonitor.push(obj);
        // }

        // async Join() {
        //     this.$emit('updateStatus', false)
        //     this.mainJoin = 0
        //     this.allMonitor.forEach(async (v) => {
        //         let hall = v.roomId;
        //         if(hall) {
        //             await v.monitor.Join(hall).then(() => {
        //                 console.log('main Join 2')
        //                 setTimeout(() => {
        //                     this.mainJoin += 1;
        //                     if(this.mainJoin === this.allMonitor.length){
        //                         this.$emit('updateStatus', true)
        //                     }
        //                 }, 1000);
        //             });
        //         }
        //     });
        // }

        // async ClientPush(event, monitor) {
        //     let stream = event.stream;
        //     await monitor.Watch(stream, {
        //         audio: true,
        //         video: true,
        //     });
        // }

        // async ClientJoined(event) {
        //     let stream = event.stream;
        //     let userId = stream.getUserId();
        //     this.$refs[userId][0]?.Bind(stream);
        // }


        Calc(count, totalWidth, totalHeight) {
            let row = 1,
                col = 1;
            if (count <= 1) {
                row = 1;
                col = 1;
            } else if (count <= 2) {
                col = 2;
            } else if (count <= 4) {
                col = 2;
                row = 2;
            } else if (count <= 6) {
                col = 3;
                row = 2;
            } else if (count <= 9) {
                col = 3;
                row = 3;
            }

            this.player.width = totalWidth / col;
            this.player.height = totalHeight / row;
        }


        GetRoomText(i) {
            const room = this.items.length > i ? this.items[i] : null;
            if(room){
                return room.examinationSiteName + room.examinationRoomName + room.positionDesc;
            }
            return ''
        }

        GetRoomCode(i) {
            const roomCode = this.items.length > i ? this.items[i].examinationRoomCode : null;  // eslint-disable-line
            return roomCode
        }

        GetRoomRoomName(i) {
            const roomName = this.items.length > i ? this.items[i].examinationRoomName : null;  // eslint-disable-line
            return roomName
        }

        GetRoomPosition(i) {
            var positionDesc = this.items.length > i ? this.items[i].positionDesc : null;  // eslint-disable-line
            if(positionDesc && this.items[i].examinationRoomType==1)
                positionDesc='';
            return positionDesc
        }

        GetPlayerUrl(i) {
            // return `${document.location.protocol}//player.live.zfoline.net/${userId}.m3u8`;
            const userId =  this.items.length > i ? this.items[i].userId : null;
            return `${document.location.protocol}//player.live.zfoline.net/${userId}.m3u8`;
        }

        formatDate() {
            let date = new Date();
            let year = date.getFullYear(); // 年
            let month = date.getMonth() + 1; // 月
            let day = date.getDate(); // 日
            let week = date.getDay(); // 星期
            let weekArr = [
                '星期日',
                '星期一',
                '星期二',
                '星期三',
                '星期四',
                '星期五',
                '星期六',
            ];
            let hour = date.getHours(); // 时
            hour = hour < 10 ? '0' + hour : hour;
            let minute = date.getMinutes(); // 分
            minute = minute < 10 ? '0' + minute : minute;
            let second = date.getSeconds(); // 秒
            second = second < 10 ? '0' + second : second;
            let currentDate = date.getDate();
            day = currentDate < 10 ? ('0' + currentDate) : currentDate;
            this.nowDate = `${year}-${month}-${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
        }
        updateBandwidth(id, bandwidth) {
          this.items.forEach(item=>{
            if(item.userId==id){
              this.$set(item, 'itemBandWidth', bandwidth);
            }
          })
          // console.log(this.items,id, bandwidth,1231);
        }
        @Emit()
        prev() {}

        @Emit()
        next() {}

    }
</script>

<style lang="scss" scoped>
    .monitor-div {
        position: relative;
    }
    .no-gutters {
        display: flex;
        .video-item {
            width: 33.3%;
            position: relative;
            border: 1px solid rgba(255,255,255,0.05);
        }
        .show {
            display: block;
        }
        .hidden {
            display: none;
            // position: absolute;
            // left: 0;
            // top: 0;
            // z-index: -1;
        }
    }
</style>
