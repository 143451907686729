<template>
    <div>
      <h1>HLS 播放器示例</h1>
      <HlsPlayer src="http://player.live.zfoline.net/a9716ad9-54ba-464e-a256-4720e053c1a4.m3u8" />
    </div>
  </template>
  
  <script>
  import HlsPlayer from '../components/hlsPlayer/index.vue';
  
  export default {
    components: {
      HlsPlayer,
    },
  };
  </script>
  