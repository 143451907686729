<template>
    <div>
        <v-row class="monitor" no-gutters>
            <v-col cols="2" class="pr-3">
                <div class="scroll tree-scroll">
                    <examinatintree @func="treeView"></examinatintree>
                </div>
            </v-col>
            <v-col>
                <v-card class="">
                    <h2 class="text-center">{{ roomName }}视频回放</h2>
                    <div class="wrapper-content" v-show="videoList.length > 0">
                        <div class="dplayer-wrapper">
                            <div id="dplayer" style="width: 100%;height: 100%;"></div>
                            <span class="time-item">
                                {{ showTime }}
                            </span>
                        </div>
                        <div class="select-wrapper">
                            <div
                                class="select-item"
                                v-for="(item, index) in videoList"
                                :key="index"
                                @click="changeVideo(item)"
                                :class="{ active: item.index == activeIndex }"
                            >
                                <span class="time">
                                    {{ item.startTime }}~{{
                                        item.endTime.substring(11)
                                    }}
                                </span>
                                <span>{{ item.index }}</span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-show="videoList.length === 0"
                        class="text-center mx-auto monitor-size"
                    >
                        <p
                            v-if="type !== 3"
                            class="title white--text"
                            style="padding-top: 25%"
                        >
                            请选择考场设备播放视频
                        </p>
                        <p
                            v-if="type === 3"
                            class="title white--text"
                            style="padding-top: 25%"
                        >
                            没有视频
                        </p>
                    </div>
                </v-card>
            </v-col>


        </v-row>
    </div>
</template>

<script>
import { Vue, Component, Inject } from 'vue-property-decorator';
import VideoPlayer from '@/components/videoPlayback';
import examinatintree from '@/components/tree/examinatintree';
import DPlayer from '@/components/js/DPlayer.min.js';

Date.prototype.Format = function (fmt) {
    var o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, this.getFullYear() + '');
    for (var k in o)
        if (new RegExp('(' + k + ')').test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1
                    ? o[k]
                    : ('00' + o[k]).substr(('' + o[k]).length)
            );
    return fmt;
};

let dpMain = '';

export default
@Component({
    components: {
        VideoPlayer,
        examinatintree,
    },
})
class ExaminationRoomMonitorViewPageComponent extends Vue {
    static name = window.$views.ExamCsp.Playback.Index;
    @Inject()
    repository;

    videoList = [];
    equipmentNo = '';
    roomName = '';
    activeIndex = '';
    current = {
        startTime: '',
    };
    type = '';
    showTime = ''
    // selectItem = '';

    treeView(data) {
        this.roomName = data.roomName;
        if (data.type === 3) {
            this.activeIndex = '';
            this.initPlayer();
            this.equipmentNo = data.name;
            this.GetVideos(data.name);
        }
        this.type = data.type;
    }

    async UploadIsOver(data) {
        if (data + 1 < this.$refs.subQuestion.length) {
            this.$refs.subQuestion[data + 1].isShowVideo = true;
            this.$refs.subQuestion[data + 1].audioPlay();
            for (let m = 0; m < this.$refs.subQuestion.length; m++) {
                if (m != data + 1) {
                    this.$refs.subQuestion[m].isShowVideo = false;
                }
            }
        }
    }

    mounted() {
        this.GetVideos();
    }

    initPlayer() {
        dpMain = new DPlayer({
            container: document.getElementById('dplayer'),
            mutex: false,
            lang: 'zh-cn',
            theme: '#0773ed',
            playbackSpeed: [0.5, 0.75, 1.0, 1.25, 1.5, 2, 3, 5, 10, 16],
            video: {
                url: '',
            },
        });
        let self = this;
        dpMain.on('timeupdate', function () {
            const time = dpMain.video.currentTime;
            const temp = Number(time).toFixed(0);
            let sum = new Date(self.current.startTime).getTime() + temp * 1000;
            let result = new Date(sum).Format('yyyy-MM-dd hh:mm:ss');
            // console.log(result);
            self.showTime = result
        });
    }

    GetVideos(name) {
        this.videoList = [];
        let protocol = location.protocol;
        const isHttp = protocol === 'http:';
        this.repository.Video.GetVideos({ equipmentNo: name,examId:this.context.examId }).then((res) => {
            this.videoList = res.data.map((v, j) => {
                if (isHttp && v.videoUrl.indexOf('https') > -1) {
                    v.videoUrl = v.videoUrl.replace(/^https/, 'http');
                }
                v.index = `第${j + 1}段`;
                return v;
            });
            console.log(this.videoList);
            if (this.videoList.length > 0) {
                dpMain.switchVideo({
                    url: this.videoList[0].videoUrl,
                });
                // this.selectItem = this.videoList[0].videoUrl;
                this.activeIndex = this.videoList[0].index;
                dpMain.play();
                this.current = this.videoList[0];
            }
        });
    }

    changeVideo(item) {
        // console.log(item)
        this.current = item;
        this.activeIndex = item.index;
        dpMain.switchVideo({
            url: item.videoUrl,
        });
        dpMain.play();
    }
}
</script>

<style lang="scss" scoped>
.monitor {
    // background-color: RGB(28, 29, 48);
    // color: #fff;
}
.tree-scroll {
    height: calc(100vh - 84px);
    // background-color: #000;
}

.monitor-size {
    background-color: #000;
    height: 800px;
}
.monitor-div {
    width: 1440px;
}
.wrapper-content {
    width: 100%;
    display: flex;
    padding: 10px;
    height: calc(100vh - 130px);
}
.select-wrapper {
    flex: 1;
    overflow-y: auto;
    /* position: absolute;
    right: 0px;
    top: 60px; */
    .select-item {
        position: relative;
        max-width: 250px;
        width: 100%;
        height: 60px;
        cursor: pointer;
        // background: black;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid RGB(65, 154, 255);
        border-right: 1px solid RGB(65, 154, 255);
        background-color: RGB(28, 29, 48);
        &:first-child {
            border-top: 1px solid RGB(65, 154, 255);
        }
        &.active {
            background-color: RGB(65, 154, 255);
        }
        .time {
            position: absolute;
            color: #fff;
            // left: 50%;
            // transform: translateX(-50%);
            text-align: center;
            bottom: 0;
            font-size: 12px;
            width: 100%;
        }
    }
}
.dplayer-wrapper {
    background-color: RGB(28, 29, 48);
    flex: 5;
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    /* position: absolute;
    top: 100px;
    padding: 30px; */
    .time-item {
        position: absolute;
        right: 100px;
        color: yellow;
        top: 40px;
        font-weight: 900;
        font-size: 18px;
    }
}
</style>
