import sender from '../request';
export default class ExaminationSiteRepository {

    checkIsPhysicalExamSite() {
        return JSON.parse(localStorage.getItem('enableisPhysicalExamSite'))
    }

    GetExaminationSites(data) {
        let copy = {...data}
        if(this.checkIsPhysicalExamSite()){
            copy = {...data, isPhysicalExamSite: true}
        }
        return sender.getWithUnCatch("/ExaminationSite/GetExaminationSites", copy)
    }

    GetShowExaminationSites(data) {
        let copy = {...data}
        if(this.checkIsPhysicalExamSite()){
            copy = {...data, isPhysicalExamSite: true}
        }
        return sender.getWithUnCatch("/ExaminationSite/GetShowExaminationSites", copy)
    }
    //导入考场考点数据
    ImportExamSiteAndRoom(data,examId) {
        return sender.postWithUnCatch("/ExaminationSite/ImportExamSiteAndRoom", {data:data,examId:examId})
    }

    AddOrUpdate(data) {
        return sender.postWithUnCatch("/ExaminationSite/AddOrUpdate", data)
    }

    Delete(data) {
        return sender.getWithUnCatch("/ExaminationSite/Delete", data)
    }
    GetExistSiteArea(siteCode,examId) {
        return sender.getWithUnCatch("/ExaminationSite/GetExistSiteArea?siteCode="+siteCode+'&&examId='+examId)
    }
    ChangeMonitorShow(data) {
        return sender.postWithUnCatch("/ExaminationSite/ChangeMonitorShow",data)
    }

    ChangeAreaMonitorShow(data) {
        return sender.postWithUnCatch("/ExaminationSite/ChangeAreaMonitorShow",data)
    }
}
