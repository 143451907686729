<!--
 * @Descripttion :
 * @Author       : pishengjun
 * @Date         : 2021-09-24 11:10:05
 * @LastEditors  : pishengjun
 * @LastEditTime : 2022-01-02 23:40:05
 * @FilePath     : \ExamMonitorSystem.ServicePlatform.UI\src\components\MonitorView2\Extend.vue
-->
<template>
    <div class="full newMonitor">
        <monitor-view
            ref="player"
            :title="examinationSite.fullName"
            :area="examinationSite.areaName"
            :items="monitors"
            :width="width"
            :height="height"
            :count="page.count"
            :enableFil="enableFil"
            @updateStatus="updateStatus">
            <template #options>
                <v-switch class="mt-5 mx-2" v-model="autoMute" color="success" dense>
                    <template #label>静音</template>
                </v-switch>
                <v-switch class="mt-5 mx-2" v-model="enableFil" color="success" dense>
                    <template #label>自动填充</template>
                </v-switch>
                <v-switch class="mt-5 mx-2" :disabled="!canSwitch" v-model="carousel" color="success" dense>
                    <template #label>自动轮播</template>
                </v-switch>
                <div class="task_time">
                    <!-- :disabled="Boolean(carousel)"  -->
                    <el-input class="time_input"  v-model.number="taskTime"
                        @blur="validateTime"
                        @input="onInput" type="text" size="small" placeholder="" maxlength="4" />
                    <span class="unit">秒</span>
                </div>
                <v-menu bottom dark>
                    <template #activator="{ on }">
                        <!-- :disabled="!canOperate" -->
                        <v-btn  v-bind="btnProps" v-on="on">切换显示模式</v-btn>
                    </template>
                    <v-list>
                        <v-list-item-group v-model="selectedTypeIndex">
                            <v-list-item v-for="(type, index) in viewType" :key="index">
                                <v-list-item-icon>
                                    <v-icon color="success" v-if="selectedTypeIndex === index"> mdi-chevron-right
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-title> {{ type.text }} </v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                <!--  :disabled="!canOperate" -->
                <v-btn  v-bind="btnProps" @click="prev">上一页</v-btn>
                <v-btn  v-bind="btnProps" @click="next">下一页</v-btn>
                <slot name="options"></slot>
            </template>
        </monitor-view>
        <div v-if="showLeftMenu" class="selectTree">
            <div class="cont">
                <div class="menuBar">
                    <span v-if="showTree" @click="clickHidenTree">
                        <i class="el-icon-s-fold"></i> 收起 </span>
                    <span v-else @click="clickShowTree">
                        <i class="el-icon-s-unfold"></i> 展开 </span>
                </div>
                <el-tree v-show="showTree" ref="tree" :data="treeData" :props="defaultProps"
                    :expand-on-click-node="false" :default-expanded-keys="defaultExpandedKeys"
                    :default-checked-keys="defaultCheckedKeys" highlight-current :current-node-key="currentNodeKey"
                    node-key="code" accordion @node-click="handleNodeClickDebounced" />
            </div>
        </div>
    </div>
</template>
<script>
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import MonitorView from '@/components/MonitorView2/Index';
// import { Loading } from '@/infrastructures/decorators/Loading';
import Timer from '@/infrastructures/timer/timer';
// import Storage from '@/domain/storage';
import { EventBus } from '@/utils/event-bus.js';
import { debounce } from "lodash";
// var autoNextGroup = false;

export default
@Component({
    components: {
        MonitorView,
    },
})
class MonitorViewExtendComponent extends Vue {
    @Inject() notifier;
    @Inject() repository;
    @Inject() navigation;

    @Prop() width;
    @Prop() height;
    @Prop() TitleHandler;
    @Prop() PageHandler;
    @Prop() PageDataHandler;

    // =============== 下拉树 ==================
    currentIndex = 0;
    currentGroup = 0;
    showLeftMenu = false;
    autoNextGroup = false;
    showTree = true;
    defaultExpandedKeys = [];
    defaultCheckedKeys = [];
    currentNodeKey = null;
    treeData = [];
    autoMute = true;
    defaultProps = {
        label: 'fullName',
        children: 'children',
        isLeaf: 'isLeaf',
    };
    currentKey = 0;
    timer = null;
    taskTime = 60;
    canSwitch = true;
    canOperate = true;
    handleNodeClickDebounced = null;

    examinationSite = {};
    examinationSites = [];
    monitors = [];
    isAuto = false; //是否自动轮播
    btnAction = null; //默认
    page = {
        cur: 1,
        count: 1,
        size: 4,
    };

    btnProps = {
        color: 'white',
        outlined: true,
        class: 'mr-2',
    };
    selectedTypeIndex = 1;
    viewType = [
        { text: '单个', value: 1 },
        { text: '四宫格', value: 4 },
        { text: '六宫格', value: 6 },
        { text: '九宫格', value: 9 },
    ];
    enableCarousel = false;
    carousel = false;
    enableFil = true;
    // 切换时间N分钟
    carouselTimer = null;

    created() {
        this.handleNodeClickDebounced = debounce(this.handleNodeClick, 300);
    }

    async mounted() {
        window.addEventListener('beforeunload', this.beforeunloadFn);
        this.showLeftMenu = this.context.leftMenu ? true : false;
        this.autoNextGroup = this.showLeftMenu;
        // await this.GetExaminationAreaTreeData();
        let type = this.viewType[this.selectedTypeIndex];
        // let isFirstPage = this.page.cur === 1;
        this.page.size = type.value;
        this.page.cur = 1;
        if(this.showLeftMenu === true) {
            this.btnAction = 'next';
            await this.GetExaminationAreaTreeData();
        } else {
            await this.GetExaminationSiteData();
            await this.GetOnlineEquipmentCount();
            await this.GetOnlineEquipments();
        }
        this.carousel = true;
        this.mutedAndPlay()
    }

    beforeDestroy() {
        this.beforeunloadFn();
        window.removeEventListener('beforeunload', this.beforeunloadFn);
    }

    @Watch('selectedTypeIndex', { immediate: false, })
    OnViewTypeChanged() {
        let type = this.viewType[this.selectedTypeIndex];
        // let isFirstPage = this.page.cur === 1;
        this.page.size = type.value;
        this.page.cur = 1;
        // if (isFirstPage) {

        this.btnAction = 'update';
        EventBus.$emit('resetMonitor');
        // this.LoadPageData();
        this.handleUpdate();
    }

    mutedAndPlay(){
        setTimeout(() => {
            console.log('mutedAndPlay', this.autoMute)
             let el = document.querySelectorAll('.vcp-player video')
                el.forEach(v=> {
                    v.muted = this.autoMute
                    v.play()
               })
        }, 3000);
    }


    @Watch('autoMute')
    async OnMuteChanged(val) {
        // if(val) {
        //     this.disableVoice();
        //     this.$refs.player.muted();
        // } else {
        //     this.enableVoice();
        //     this.$refs.player.unmuted();
        // }
        console.log('autoMute' , val)
        let el = document.querySelectorAll('.vcp-player video')
                el.forEach(v=> {
                    v.muted = val
        })
    }

    @Watch('carousel')
    getGarousel(val) {
        this.enableCarousel = val;
    }

    @Watch('enableCarousel')
    async OnCarouselStatusChanged(val) {
        this.isAuto = val;
        if(val) {
            this.carouselTimer = Timer.CreateFromSecond(this.VideoCarousel, this.taskTime);
            // this.btnAction = 'next';
            this.autoNextGroup = true;
            this.carouselTimer.Start();
        } else {
            this.autoNextGroup = false;
            this.btnAction = null;
            if(this.carouselTimer) this.carouselTimer.Stop();
            this.carouselTimer = null;
        }
    }

    @Watch('examinationSite')
    setTreeKey() {
        // console.log('检测到this.examinationSite改变了==>',this.examinationSite)
        this.$nextTick(() => {
            this.defaultExpandedKeys = [this.examinationSite.code];
            this.defaultCheckedKeys = [this.examinationSite.code];
            this.$refs.tree.setCurrentKey(this.examinationSite.code);
        });
    }

    // 重新初始化载入数据
    async initSiteFn(areacode, cIndex) {
        await this.GetExaminationSiteData({ code: '', areacode: areacode, type: 1 }, cIndex);
        await this.GetOnlineEquipmentCount();
        await this.GetOnlineEquipments();
    }

    async hanldeOneLevelClick() {
        let areacode = this.treeData[this.currentGroup].code;
        await this.GetExaminationSiteData({ code: '', areacode: areacode, type: 1 }, this.currentIndex);
        await this.GetOnlineEquipmentCount();
        await this.GetOnlineEquipments2();
    }

    async handleUpdate() {
        this.examinationSites = this.LoopFn(this.examinationSites);
        console.log('当前执行：刷新模式', this.examinationSites);
        if(this.examinationSites && Array.isArray(this.examinationSites)) {
            this.examinationSite = this.examinationSites[this.currentIndex];
            if(this.examinationSite) {
                this.page.code = this.examinationSite.code;
                this.page.cur = 1;
            }
            await this.GetOnlineEquipmentCount(this.page);
            await this.GetOnlineEquipments();
        }
    }

    beforeunloadFn() {
        if(this.carouselTimer) this.carouselTimer.Stop();
        this.carouselTimer = null;
    }
    // 每隔 300ms 执行一次
    onInput(event) {
        const value = event.target.value;
        // 只保留整数部分
        event.target.value = value.replace(/\D/g, '');
        // 更新 v-model 绑定的值
        this.taskTime = Number(event.target.value);
    }

    updateStatus(flag) {
        this.canOperate = flag;
    }

    enableVoice() {
        // let el = document.querySelectorAll('.notPlay-icon');
        // if(el.length > 0) {
        //     el.forEach((v) => {
        //         v.click();
        //     });
        // }
    }

    disableVoice() {
        // let el = document.querySelectorAll('.play-icon');
        // if(el.length > 0) {
        //     el.forEach((v) => {
        //         v.click();
        //     });
        // }
    }

    clickShowTree() {
        this.showTree = true;
    }

    clickHidenTree() {
        this.showTree = false;
    }

    // 递归
    LoopFn(array) {
        for(let i = 0; i < array.length; i++) {
            const el = array[i];
            array[i].fullName = `${el.code}-${el.name}`;
            if(el.children && Array.isArray(el.children)) {
                el.children = el.children.map((val, cIndex) => {
                    val.index = el.index;
                    val.cIndex = cIndex;
                    return val;
                });
                el.children = this.LoopFn(el.children);
            }
        }
        return array;
    }

    // 点击数节点,
    async handleNodeClick(node) {
        console.log('点击数节点===>', node.index, node.cIndex, node,);
        const { level, code, index, cIndex, pCode } = node;
        this.currentGroup = index || 0;
        this.currentIndex = cIndex;
        this.autoNextGroup = false;
        if(level == 1) {
            await this.hanldeOneLevelClick(code); //  cIndex 默认为0 ,查询第一个
        } else if(level == 2) {
            await this.initSiteFn(pCode, cIndex);
            this.currentIndex = cIndex;
        }
    }

    // 获取数所有节点
    async GetExaminationAreaTreeData() {
        this.currentIndex = 0;
        this.currentGroup = 0;
        let roomType = localStorage.getItem('roomType') || -1;
        await this.repository.ExaminationDistrict.GetExaminationAreaTreeNew(
            this.context.examId,
            roomType
        ).then((res) => {
            res.data = res.data.map((val, index) => {
                val.index = index;
                return val;
            });
            this.treeData = this.LoopFn(res.data).filter(v => v.children !== null);
        });
        await this.initSiteFn(this.treeData[0].code, 0);
    }

    // =============== 下拉树 END ==================

    async GetOnlineEquipmentCount() {
        if(!this.PageHandler) return;
        let count = await this.PageHandler(this.page);
        this.page.count = count;
    }

    async GetOnlineEquipments() {
        if(!this.PageDataHandler) return;
        this.monitors.length = 0;
        this.monitors = (await this.PageDataHandler(this.page)) || [];
        console.log('this.monitors======>', this.monitors);
        if(
            !this.monitors ||
            (Array.isArray(this.monitors) && this.monitors.length === 0)
        ) {
            // if(this.examinationSite){console.log(`【${this.examinationSite.areaName}-${this.examinationSite.name}】当前考点无数据0条，继续请求...`) }
            // 没有数据时处理,下一个
            // alert('没有数据，下一个')

            this.btnAction && this.LoadPageData();
            // if(this.btnAction && this.btnAction !== 'update') {
            //     this.LoadPageData()
            // }
        }
    }

    async GetOnlineEquipments2() {
        if(!this.PageDataHandler) return;
        this.monitors.length = 0;
        this.monitors = (await this.PageDataHandler(this.page)) || [];
        console.log('this.monitors======>', this.monitors);
        if(
            !this.monitors ||
            (Array.isArray(this.monitors) && this.monitors.length === 0)
        ) {
            let info = this.getNextGroupAndIndex(this.treeData, this.currentGroup, this.currentIndex)
            this.currentGroup = info.nextGroup
            this.currentIndex = info.nextIndex
            if(this.btnAction && this.showLeftMenu && this.enableCarousel) {
                let areacode = this.treeData[this.currentGroup].code;
                await this.GetExaminationSiteData({ code: '', areacode: areacode, type: 1 }, this.currentIndex);
                await this.GetOnlineEquipmentCount();
                await this.GetOnlineEquipments2();
            }
        }
    }

    async GetExaminationSiteData(param, cIndex = 0) {
        if(!this.TitleHandler) return;
        this.examinationSites = await this.TitleHandler(param);
        if(this.examinationSites.length > 0) {
            // 赋值 fullName
            this.examinationSites = this.LoopFn(this.examinationSites);
            this.examinationSite = this.examinationSites[cIndex];
            this.page.code = this.examinationSite ? this.examinationSite.code : ''; //更新为最新的code
        }
    }

    prev() {
        if(this.showLeftMenu) {
            this.autoNextGroup = true;
        }
        this.btnAction = 'prev';
        this.LoadPageData();
    }

    next() {
        if(this.showLeftMenu) {
            this.autoNextGroup = true;
        }
        this.btnAction = 'next';
        this.LoadPageData();
    }

    async VideoCarousel() {
        // this.btnAction = 'next'
        // // EventBus.$emit('resetMonitor');
        // // this.currentKey++;
        // await this.LoadPageData();
        this.next()
    }

    validateTime(event) {
        const value = event.target.value;
        if(Number(value) < 30) {
            this.taskTime = 30;
            this.notifier.warn('最小间隔为30s');
        }
    }

    processSite() {
        this.examinationSites = this.LoopFn(this.examinationSites);
    }

    // @Loading('数据加载中')
    async LoadPageData() {
        this.processSite();
        if(this.btnAction == 'prev') {
            this.handlePrev();
        }
        else if(this.btnAction == 'next') {
            this.handleNext();
        }
    }

    getNextGroupAndIndex(treeData, currentGroup, currentIndex) {
        if(!Array.isArray(treeData) || treeData.length === 0) {
            console.warn('treeData 数据为空或无效');
            return { nextGroup: null, nextIndex: null };
        }
        // 当前 group 的 children
        const currentChildren = treeData[currentGroup]?.children || [];

        // 如果当前组有子节点且当前索引未到最后一个子节点
        if(currentIndex + 1 < currentChildren.length) {
            return {
                nextGroup: currentGroup,
                nextIndex: currentIndex + 1,
            };
        }

        // 当前组的子节点已遍历完，切换到下一组
        const nextGroup = (currentGroup + 1) % treeData.length; // 循环切换 group
        const nextChildren = treeData[nextGroup]?.children || [];

        // 判断下一组是否有子节点
        return {
            nextGroup: nextGroup,
            nextIndex: nextChildren.length > 0 ? 0 : null, // 如果没有子节点，返回 null
        };
    }

    async handleNext() {
        console.log('下一页', 'currentGroup', this.currentGroup, 'currentIndex', this.currentIndex);
        // 判断当前考点的分页是否大于总数
        const pagination = Math.ceil(this.page.count / this.page.size);
        if(this.page.cur >= pagination) {
            // 当前 group 最后一个
            if(this.currentIndex >= this.examinationSites.length - 1) {
                // 自动轮播模式
                if(this.isAuto === true) {
                    if(this.showLeftMenu) {
                        let info = this.getNextGroupAndIndex(this.treeData, this.currentGroup, this.currentIndex);
                        this.currentGroup = info.nextGroup;
                        this.currentIndex = info.nextIndex;
                        // 先 leave 再 initSiteFn
                        console.log('下一个判断', this.currentGroup, this.currentIndex);
                        // EventBus.$emit('leaveRoom');
                        let areacode = this.treeData[this.currentGroup].code;
                        await this.GetExaminationSiteData({ code: '', areacode: areacode, type: 1 }, this.currentIndex);
                        await this.GetOnlineEquipmentCount();
                        await this.GetOnlineEquipments();
                        // await this.initSiteFn(this.treeData[this.currentGroup].code, 'next');
                        return false;
                    } else {
                        this.currentIndex = 0;
                        this.examinationSite = this.examinationSites[this.currentIndex];
                        // this.notifier.warn('已经是最后的考点!');
                        this.page.code = this.examinationSite.code;
                        this.page.cur = 1;
                        await this.GetOnlineEquipmentCount(this.page);
                        await this.GetOnlineEquipments();
                        return;
                    }
                }
                // 非自动轮播模式
                else {
                    // console.log('非自动轮播模式');
                    // 考区模式轮播
                    if(
                        this.autoNextGroup === true &&
                        this.currentGroup < this.treeData.length - 1
                    ) {

                        this.currentGroup = this.currentGroup + 1;
                        this.currentIndex = 0;
                        // EventBus.$emit('leaveRoom');
                        await this.initSiteFn(
                            this.treeData[this.currentGroup].code,
                            this.currentIndex
                        );
                        // this.currentIndex = this.currentIndex == this.examinationSites.length - 1 ? 0 : this.currentIndex + 1;
                        this.examinationSite = this.examinationSites[this.currentIndex];
                        this.page.code = this.examinationSite.code;
                        this.page.cur = 1;
                        return;
                    }
                    // 考区模式到达最后一个考点
                    if(
                        this.autoNextGroup === true &&
                        this.currentGroup + 1 == this.treeData.length &&
                        this.currentIndex + 1 == this.examinationSites.length
                    ) {
                        this.notifier.warn('已经到达最后一个考点!');
                        return false;
                    }
                    if(this.autoNextGroup === false && this.currentIndex + 1 == this.examinationSites.length) {
                        this.notifier.warn('提示：已经到达最后一个考点!');
                        return false;
                    }
                }
                return false;
            }
            if(this.showLeftMenu) {
                let info = this.getNextGroupAndIndex(this.treeData, this.currentGroup, this.currentIndex);
                this.currentGroup = info.nextGroup;
                this.currentIndex = info.nextIndex;
            } else {
                if(this.currentIndex + 1 == this.examinationSite.length) {
                    this.currentIndex = 0;
                } else {
                    this.currentIndex = this.currentIndex + 1;
                }
            }
            this.examinationSite = this.examinationSites[this.currentIndex];
            console.log('next',this.currentIndex, this.examinationSite)
            this.page.code = this.examinationSite.code;
            this.page.cur = 1;
            // console.log("当前执行:切换下一个考点",currentIndex,this.examinationSite.name,this.page)
        } else {
            // 考区内的下一页
            this.page.cur++;
        }
        await this.GetOnlineEquipmentCount(this.page);
        await this.GetOnlineEquipments();
    }

    async handlePrev() {
        console.log('上一页', 'currentGroup', this.currentGroup, 'currentIndex', this.currentIndex);
        if(this.currentGroup == 0 && this.currentIndex <= 0) {
            if(this.showLeftMenu) {
                console.log('currentIndex', this.currentIndex);
                this.notifier.warn('已经是第一个考点!');
                // this.enableCarousel = false;
                return false;
            } else {
                if(this.page.cur === 1) {
                    this.notifier.warn('已经是第一个考点!');
                    return false;
                }
            }

        }
        // 判断当前考点的分页是否小于1
        if(this.page.cur <= 1) {
            this.currentIndex = this.currentIndex <= 0 ? 0 : this.currentIndex - 1;
            // console.log('prev',currentIndex, this.examinationSite)
            this.examinationSite = this.examinationSites[this.currentIndex];
            this.page.code = this.examinationSite ? this.examinationSite.code : '';
            // alert('page code'+ this.page.code)
            console.log('page code', this.page, this.examinationSite, this.currentIndex);
            this.page.cur = 1;
            // console.log("当前执行:切换上一个考点",this.page)
            // 判断是否到达第一个考点
            if(this.currentIndex == 0) {
                if(!this.showLeftMenu) {
                    await this.GetOnlineEquipmentCount(this.page);
                    await this.GetOnlineEquipments();
                    return;
                }
                // 必然小于，判断没意义吧
                if(this.currentGroup < this.treeData.length) {
                    if(this.currentGroup > 0) {
                        // EventBus.$emit('leaveRoom');
                        //    alert('换考区')
                        // currentGroup--;
                        this.currentGroup = this.currentGroup - 1;
                        // console.log(`即将切换考区${currentGroup}：`,this.treeData[currentGroup])
                        await this.initSiteFn(
                            this.treeData[this.currentGroup].code,
                            this.currentIndex
                        );
                        return;
                    } else {
                        this.currentIndex = this.currentIndex <= 0 ? 0 : this.currentIndex - 1;
                        this.examinationSite = this.examinationSites[this.currentIndex];

                        this.page.code = this.examinationSite ? this.examinationSite.code : '';
                        console.log('执行到这里', this.examinationSite, this.currentIndex, this.page.code);
                        this.initSiteFn(this.treeData[this.currentGroup].code, this.currentIndex);
                        // EventBus.$emit('leaveRoom');
                    }
                }
                return false;
            }
        } else {
            // alert('上一页内容')
            // currentIndex-=1
            this.currentIndex = this.currentIndex - 1;
            this.page.cur--;
            // console.log("当前执行:切换当前考点的上一页",this.page)
        }
        await this.GetOnlineEquipmentCount(this.page);
        await this.GetOnlineEquipments();
    }
}
</script>
<style lang="scss" scoped>
.task_time {
    position: relative;

    .time_input {
        width: 80px;
        font-size: 14px;
        color: #fff;
        // margin-top: 20px;
        margin-right: 5px;
    }

    .unit {
        position: absolute;
        right: 6px;
        top: 6px;
    }
}

.full {
    width: 100%;
    height: 100%;
    position: relative;
}

.newMonitor {
    .selectTree {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 48px;
        max-height: calc(100vh - 48px);
        overflow-x: hidden;
        overflow-y: auto;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;

        // min-width: 160px;
        ::v-deep .el-tree {
            background: none;
            color: rgba(255, 255, 255, 0.85);
            font-size: 16px;
            line-height: 36px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            padding: 10px;

            .el-tree-node__label {
                font-size: 16px;
            }

            .el-menu-item.is-active {
                background-color: rgb(201, 188, 80) !important;
            }

            .el-tree-node__content {
                height: 36px;
                padding-right: 10px;

                &:hover {
                    // background: #89c2f7;
                    background: rgba(255, 255, 255, 0.1);
                }
            }

            .el-tree-node:focus>.el-tree-node__content {
                // background-color: #5daaf0;
                background: rgba(93, 170, 240, 0);
            }

            .el-tree-node.is-current>.el-tree-node__content {
                // background: #5daaf0;
                background: rgba(93, 170, 240, 0.2);
            }
        }

        .cont {
            // padding: 10px;
            background: rgba(255, 255, 255, 0.08);

            .menuBar {
                position: sticky;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10;
                background: rgba(0, 0, 0, 0.9);
                padding: 8px 15px;

                span {
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
<style scoped>
/* 滚动条样式 */
/*Width*/
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/*Track*/
::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

/*Handle*/
::-webkit-scrollbar-thumb {
    background-color: #11171a;
    border-radius: 15px;
}
</style>
<style lang="scss">
.newMonitor .time_input.el-input.is-disabled .el-input__inner {
    color: #C0C4CC;
    background-color: #000;
}

.newMonitor .time_input.el-input--small .el-input__inner {
    color: #fff;
    background-color: #000;
}
</style>
