<template>
    <div style="height: 100%; width: 100%;" :class="{videoFil:enableFil}" class="vcp-player">
      <video
        :id="vid"
        ref="videoPlayer"
        autoplay
        style="width: 100%; height: 100%;z-index: 0;"
      ></video>
      <div class="exam-room-info">
          <slot name="title"></slot>
          <div clsss="band_info" style="display: flex;align-items: center;">实时带宽: {{ bandwidth }} kbps</div>
      </div>
      <!-- <div class="band">
        <p>实时带宽: {{ bandwidth }} kbps</p>
      </div> -->
      <div class="operate button-tip">
            <el-tooltip class="item" effect="dark" content="全屏查看" placement="top-start">
            <el-button @click="handleFull" class="el-icon-full-screen"></el-button>
      </el-tooltip>
        </div>
    </div>
  </template>

  <script>
  import screenfull from 'screenfull';
  export default {
    data() {
      return {
        hls: null,
        bandwidth: 0, // 实时带宽（kbps）
      };
    },
    props: {
      src: {
        type: String,
        required: true, // 视频流地址
      },
      vid: {
        type: String,
        required: true,
      },
      enableFil: {
        type: Boolean,
        required: true,
      }
    },
    mounted() {
      this.initHlsPlayer();
    },
    watch: {
      src: {
        handler(){
          this.initHlsPlayer();
        }
      },
      deep: false,
      immediate: false,
    },
    methods: {
      handleFull() {
          let id = this.vid
          let target = document.getElementById(id)
          if (screenfull.isEnabled) {
              screenfull.request(target)
          }
      },
      initHlsPlayer() {
        const video = this.$refs.videoPlayer;
        if (window.Hls.isSupported()) {
          this.hls = new window.Hls();
          // 加载 HLS 源
          this.hls.loadSource(this.src);
          this.hls.attachMedia(video);

          // 监听分片加载事件
          this.hls.on(window.Hls.Events.FRAG_LOADED, (event, data) => {
            var stats = data.frag.stats;
            this.bandwidth = (stats.bwEstimate  / 1024 / 1024).toFixed(2);
          });

          this.hls.on(window.Hls.Events.ERROR, (event, data) => {
            console.error('HLS 播放器错误:', data);
          });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
          video.src = this.src;
          video.play();
        } else {
          console.error('HLS 不支持');
        }
      },
    },
    beforeDestroy() {
      if (this.hls) {
        this.hls.destroy();
      }
    },
  };
  </script>

  <style lang="scss">
  /* video {
    border: 1px solid #ccc;
  } */
  .videoFil video {
        object-fit: fill !important;
    }
    .band {

    }
  </style>
<style lang="scss" scoped>
    .exam-room-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        width: 100%;
        background-color: rgba(0,0,0,0.75);
        color: #fff;
    }
    .RoomInfo {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0;
        color: #fff;
        background-color: rgba(0,0,0,0.75);
        padding: 6px;
        right: 0;
    }
    .operate {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
    }
    .button-tip {
      .el-button {
          background: transparent !important;
          border: none !important;
          font-size: 24px;
          color: white;
          padding: 1px 10px;
      }
  }

</style>
<style>
  .band_info {
    display: flex;
    align-items: center;
  }
</style>
