<template>
    <div class="stream" style="height: 100%;">
            <!-- <registry
                :ref="this.equipment.monitorId"
                :registry="{ id: this.equipment.monitorId }"
            ></registry> -->
            <video-player
                    v-show="showVideo"
                    class="monitor-div"
                    :url="GetPlayerUrl(this.equipment)"
                    :vid="'tcplayer'+i"
                >
                </video-player>
    </div>
</template>

<script>
    /* eslint-disable */
    import TrtcMonitor from '@/infrastructures/trtc/monitor2';
    import registry from '@/components/trtc/registry2';
    import VideoPlayer from '@/components/videoPlayer/index';
    export default {
        name: 'Stream',
        inject: ['repository'],
        components: {
            registry,
            VideoPlayer
        },
        data() {
            return {
                showVideo:false,
                equipment: {},
                id: '',
                token: '',
                monitor: {},
            };
        },
        methods: {
            GetPlayerUrl(item) {
                const userId = item.userId;
                return `${document.location.protocol}//player.live.zfoline.net/${userId}.m3u8`;
            },
            async GetInfo() {
                const res =
                    await this.repository.ExaminationRoom.GetEquipmentInfoById({
                        id: this.id,
                        token: this.token,
                    });

                this.equipment = res.data;
                this.showVideo = true;
                console.log('this.equipment', this.equipment);
                // this.monitor = TrtcMonitor.CreateMonitor(
                //     this.equipment.monitorId,
                //     this.equipment.monitorIdSig,
                //     Number(this.equipment.sdkAppId)
                // );
                // this.monitor.WhenClientJoin(this.ClientPush);
                // // 当本考场考生加入后
                // this.monitor.AfterClientJoin(this.ClientJoined);
                // this.monitor.Join(Number(this.equipment.trtcRoomId));
            },
            async ClientJoined(event) {
                let stream = event.stream;
                // let userId = stream.getUserId();
                this.$refs[this.equipment.monitorId].Bind(stream);
            },
            async ClientPush(event) {
                let stream = event.stream;
                await this.monitor.Watch(stream, {
                    audio: false,
                    video: true,
                });
            },
        },
        mounted() {
            this.token = this.$route.query.token;
            this.id = this.$route.query.id;
            this.GetInfo();
        },
        beforeDestroy() {
            this.monitor.Leave()
        },
    };
</script>
