<!--
 * @Descripttion :
 * @Author       : pishengjun
 * @Date         : 2021-09-24 09:38:45
 * @LastEditors  : pishengjun
 * @FilePath     : \ExamMonitorSystem.ServicePlatform.UI\src\components\videoPlayer\index.vue
-->
<template>
    <div class="videoWrap" :class="{videoFil:enableFil}">
        <div :id="vid || url" class="videoDiv" style="height: 100%; width: 100%"></div>
        <!-- <div class="examination-Room--name text-left">
            <slot name="title"></slot>
        </div> -->
        <div class="exam-room-info">
            <!-- <div class="RoomInfo"> -->
                <slot name="title"></slot>
            <!-- </div> -->

        </div>
        <div class="operate button-tip">
            <el-tooltip class="item" effect="dark" content="全屏查看" placement="top-start">
                    <el-button @click="handleFull" class="el-icon-full-screen"></el-button>
                </el-tooltip>
        </div>
    </div>
</template>

<script>
    import screenfull from 'screenfull';
    import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

    export default
    @Component
    class VideoPlayerComponent extends Vue {
        @Prop() url;
        @Prop() title;
        @Prop() code;
        @Prop() name;
        @Prop() playerstyle;
        @Prop() vid;
        @Prop() enableFil;

        mounted() {
            /* eslint-disable no-undef */
            const id = this.vid ?? this.url
            this.instance = new TcPlayer(id, {
                m3u8: this.url, //请替换成实际可用的播放地址
                autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                live: true,
                width:'100%',
                height:'100%',
                listener: this.PlayerHandler,
            });
            // const element = document.querySelector(`#${vid} video`)
            // element.addEventListener('loadedmetadata', function() {
            //     video.muted = true; // 视频加载完成后将其静音
            //     video.play(); // 启动播放器
            // });
        }

        @Watch('url', { immediate: false, deep: false })
        creatVideo(val) {
            this.instance.load(val)
            // this.instance.mute(true)
        }

        handleFull() {
                let id = this.vid
                let target = document.getElementById(id)
                if (screenfull.isEnabled) {
                    screenfull.request(target)
                }
        }

        muted() {
            console.log('muted',this.instance.mute())
            this.instance.mute(true)
            console.log('muted result',this.instance.mute())
        }

        unmuted() {
            console.log('unmuted',this.instance.mute())
            this.instance.mute(false)
            // console.log('unmuted result',this.instance.mute())
        }

        PlayerHandler(msg) {
            if (msg.type === 'pause') {
                this.instance.togglePlay();
            }
        }

        beforeDestroy() {
            this.instance.destroy();
            this.instance=null
        }

        destroyed() {
            console.log('+++++++++++++播放器被销毁===>',this.instance)
            this.instance.destroy();
            this.instance=null
            // $('#'+this.url).html("");
        }

        instance = null;
    }
</script>

<style scoped>
    .examination-Room--name {
        width: 100%;
        color: #fff;
        font-weight: bold;
        position: absolute;
        top: 0;
        right: 0;
    }
    .videoDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
        height: 100%;
    }
    .videoWrap {
        width: 100%;
        height: 100%;
    }
    .exam-room-info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        width: 100%;
        background-color: rgba(0,0,0,0.75);
        color: #fff;
    }
    .RoomInfo {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0;
        color: #fff;
        background-color: rgba(0,0,0,0.75);
        padding: 6px;
        right: 0;
    }
    .operate {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1000;
        display: flex;
        align-items: center;
    }
</style>
<style lang="scss">
    .videoFil video {
        object-fit: fill !important;
    }
    .vcp-fullscreen-toggle {
        right: -1em;
        top: 0.5em;
        display: none !important;
    }
    .button-tip {
    .el-button {
        background: transparent !important;
        border: none !important;
        font-size: 24px;
        color: white;
        padding: 1px 10px;
    }
}

</style>
