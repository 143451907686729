<template>
    <v-card class="single_video_player" style="box-shadow: none !important;">
        <div class="d-flex" style="height: 100vh;width: 100vw;">
            <div
                :id="registry.id"
                class="stream-player mx-auto"
            ></div>
        </div>
    </v-card>
</template>

<script>
    export default {
        props: ['registry',],
        inject: ['enums'],
        methods: {
            Bind(stream) {
                if (this.hasStream) {
                    this.stream.stop();
                    this.stream.close();
                }
                this.stream = stream;
                this.stream.on('player-state-changed', this.StateChange);
                this.Play();
            },
            async Play() {
                try {
                    this.MuteAudio();
                    await this.stream.play(this.registry.id,{ objectFit: 'contain' });
                    this.streamStatus.paused = false;
                    this.playSuccess = true;
                } catch (e) {
                    console.log('play failed',e);
                    this.playSuccess = false;
                    let errorCode = e.getCode();
                    if (errorCode === 0x4043) this.streamStatus.paused = true;
                }
            },
            MuteAudio() {
                if (this.stream.muteAudio()) {
                    this.audioMute = true;
                }
            },
            StateChange(event) {
                this.state = event.state;
                if (event.state === 'PAUSED') {
                    // resume audio/video playback
                    this.stream.resume();
                }
            },
        },
        async created() {
        },
        computed: {
            hasStream() {
                return Object.keys(this.stream).length !== 0;
            }
        },
        data() {
            return {
                streamStatus: {
                    paused: false,
                },
                video: {
                    state: '',
                },
                state: '',
                stream: {},
                streamFlow: {},
                audioMute: true,
                audioMuteFlow: true,
                streamStatusFlow: {
                    paused: false,
                },
                playSuccess: false,
                playSuccessFlow: false,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .registry-card {
        width: 100%;
        height: 100%;
        background-color: #000;
    }

    .stream-player {
        width: 100%;
        height: 100%;
        background-color: #000;
        video {
            object-fit: fill !important;
        }
    }
</style>
<style lang="scss">
.single_video_player {
    .stream-player {
        width: 100%;
        height: 100%;
        background-color: #000;
        video {
            object-fit: fill !important;
        }
    }
}

</style>
